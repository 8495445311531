@use '@angular/material' as mat;

@mixin request-form-component-theme($theme) {
    $foreground: map-get($theme, foreground);
    .noscript {
        > div > a {
            > span {
                color: mat.get-color-from-palette($foreground, text);
            }
            > mat-icon {
                color: mat.get-color-from-palette($foreground, icon);
            }
        }
    }
}
