@use '@angular/material' as mat;

@mixin contact-component-theme($theme) {
  $foreground: map-get($theme, foreground);
  mat-card {
    mat-card-content {
      section {
        > a,
        span {
          > span {

        color: mat.get-color-from-palette($foreground, text);
          }
          > mat-icon {
            color: mat.get-color-from-palette($foreground, icon);
          }
        }
      }
    }
  }
}
