@use '@angular/material' as mat;

@mixin link-component-theme($theme) {
  $primary: map-get($theme, primary);

  a[vmm-link] {
    color: mat.get-color-from-palette($primary);
    &:hover{
      color: mat.get-color-from-palette($primary, A700);
    }
  }
}
