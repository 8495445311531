@use '@angular/material' as mat;

@mixin navigation-component-theme($theme) {
  $foreground: map-get($theme, foreground);

  nav#main-nav {
    mat-toolbar {
      .links {
        a {
          color: mat.get-color-from-palette($foreground, text);
        }
      }
    }
  }
}
