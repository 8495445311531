// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vmm-primary: mat.define-palette(mat.$red-palette, 700);
$vmm-accent: mat.define-palette(mat.$red-palette);

// The warn palette is optional (defaults to red).
$vmm-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$vmm-theme: mat.define-light-theme((
  color: (
    primary: $vmm-primary,
    accent: $vmm-accent,
    warn: $vmm-warn,
  )
));

// Custom components
@import 'app/lib/link/link.component.scss-theme.scss';
@import 'app/core/navigation/navigation.component.scss-theme.scss';
@import 'app/contact/contact.component.scss-theme.scss';
@import 'app/lib/request-form/request-form.component.scss-theme.scss';
@mixin component-themes($theme) {
  @include link-component-theme($theme);
  @include navigation-component-theme($theme);
  @include contact-component-theme($theme);
  @include request-form-component-theme($theme);
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vmm-theme);
@include component-themes($vmm-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  &::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 1em;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.spacer {
  display: flex;
  flex: 1;
}

$ul-indent: 1.5em;
ul {
  list-style-type: none;
  li {
    padding-left: $ul-indent;
    &::before {
      content: 'keyboard_arrow_right';
      font-family: 'Material Icons';
      display: inline-block;
      margin-left: -$ul-indent;
      width: $ul-indent;
    }
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/MaterialIcons-Regular.ttf);
}
